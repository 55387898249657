import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { BrightPrenatalSeq } from "../../../assets/svgs";
import { useWindowSize } from "../../../hooks";
import { serviceIconMapper } from "../../../utils";
import ClinicalCategoryServicesMenu from "./ClinicalCategoryServicesMenu";

const ServicesMenu = ({ invert, setDropdownOpen, setShowServicesMenu }) => {
  const { biopharmaServices, clinicalCategories, clinicalServices } =
    useStaticQuery(
      graphql`
        query {
          biopharmaServices: allSanityBiopharmaServices(sort: { code: ASC }) {
            edges {
              node {
                title
                slug {
                  current
                }
              }
            }
          }
          clinicalCategories: allSanityClinicalServiceCategories(sort: {orderRank: ASC}) {
            edges {
              node {
                title
                slug {
                  current
                }
              }
            }
          }
          clinicalServices: allSanityClinicalServices {
            edges {
              node {
                title
                slug {
                  current
                }
                serviceCategory {
                  title
                  slug {
                    current
                  }
                }
                serviceSubCategory {
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      `
    );

  const { windowWidth } = useWindowSize();

  const [colors, setColors] = useState([
    "#645FE6",
    "#1AE299",
    "#FF7D50",
    "#36CBFF",
  ]);

  useEffect(() => {
    if (biopharmaServices.edges.length > 4) {
      let newColors = [];
      const amount = Math.ceil(biopharmaServices.edges.length / 4);
      for (let index = 0; index < amount; index++) {
        newColors.push(...["#645FE6", "#1AE299", "#FF7D50", "#36CBFF"]);
      }
      setColors(newColors);
    }
  }, [biopharmaServices]);

  const BiobankingIcon = serviceIconMapper["biobanking-and-sample-management"];

  return (
    <Container>
      <Row>
        <Title>
          <Link
            to="/services/clinical"
            onClick={() => {
              setDropdownOpen(false);
              setShowServicesMenu(false);
            }}
          >
            Clinical Genetic Testing Services
          </Link>
        </Title>
        {windowWidth >= 1300 && (
          <>
            {clinicalCategories.edges.length > 0 &&
              clinicalCategories.edges.map(({ node }) => (
                <ClinicalCategoryServicesMenu
                  category={node}
                  services={clinicalServices}
                  setDropdownOpen={setDropdownOpen}
                  setShowServicesMenu={setShowServicesMenu}
                />
              ))}
            <span>
              <Link
                to="/services/clinical/prenatal-seq"
                onClick={() => {
                  setDropdownOpen(false);
                  setShowServicesMenu(false);
                }}
              >
                Prenatal Screening
              </Link>
            </span>
            <div>
              <MenuItemExpanded
                to={`/services/clinical/prenatal-seq`}
                onClick={() => {
                  setDropdownOpen(false);
                  setShowServicesMenu(false);
                }}
              >
                <BrightPrenatalSeq width={100} />
                <p>Non-invasive prenatal screening</p>
              </MenuItemExpanded>
            </div>
          </>
        )}
      </Row>
      <Row>
        <Title>
          <Link
            to="/services/biopharma-and-research"
            onClick={() => {
              setDropdownOpen(false);
              setShowServicesMenu(false);
            }}
          >
            Biopharma / Research Services
          </Link>
        </Title>
        {windowWidth >= 1300 && (
          <div>
            {biopharmaServices.edges.map(({ node }, index) => {
              const SVG = serviceIconMapper[node.slug.current];
              return (
                <MenuItem
                  to={`/services/biopharma-and-research/${node.slug.current}`}
                  onClick={() => {
                    setDropdownOpen(false);
                    setShowServicesMenu(false);
                  }}
                >
                  <SVGContainer>
                    {SVG ? <SVG color={colors[index]} /> : <div />}
                  </SVGContainer>
                  <p>{node.title}</p>
                </MenuItem>
              );
            })}
          </div>
        )}
      </Row>
      <Row>
        <Title>
          <Link
            to="/services/biobanking-and-sample-management"
            onClick={() => {
              setDropdownOpen(false);
              setShowServicesMenu(false);
            }}
          >
            Biobanking and Sample Management Services
          </Link>
        </Title>
        {windowWidth >= 1300 && (
          <div>
            <MenuItem
              to={`/services/biobanking-and-sample-management`}
              onClick={() => {
                setDropdownOpen(false);
                setShowServicesMenu(false);
              }}
            >
              <BiobankingIcon color="#FF7D50" />
              <p>Biobanking and Sample Management</p>
            </MenuItem>
          </div>
        )}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.625rem 1.25rem;
  grid-column: 1/7;
  span {
    color: var(--genseq-purple);
    font-weight: 700;
    a {
      color: var(--genseq-purple);
    }
  }
  @media screen and (min-width: 1300px) {
    padding: 2.625rem 0;
    align-items: start;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 2/12;
  }
`;

const Title = styled.h4`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  a {
    color: #ffffff;
    text-decoration: none;
  }
  @media screen and (min-width: 1300px) {
    color: var(--genseq-purple);
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    a {
      color: var(--genseq-purple);
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > div {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    padding: 1.25rem;
  }
  a {
    text-decoration: none;
  }
  @media screen and (min-width: 1300px) {
    > div {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;

const MenuItemExpanded = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  gap: 0.5rem;
  p {
    font-weight: 300;
    color: var(--genseq-blue);
  }
`;

const MenuItem = styled(Link)`
  display: grid;
  cursor: pointer;
  grid-template-rows: 40px 1fr;
  font-size: 1.25rem;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  > div {
    background: white;
    height: 50px;
    width: 50px;
    padding: 0.5rem;
    margin: 0 auto;
  }
  p {
    color: var(--genseq-blue);
    font-size: 1rem;
    font-weight: 700;
    transition: all 0.3s ease-out;
  }
  svg {
    width: 24px;
    height: auto;
  }
  @media screen and (min-width: 1300px) {
    background: transparent;
    align-items: start;
    grid-template-rows: none;
    grid-template-columns: 24px 1fr;
    justify-content: start;
    text-align: left;
    > div {
      background: transparent;
      border-radius: 0;
      height: unset;
      width: unset;
      padding: 0;
      margin: 0;
    }
  }
`;

const SVGContainer = styled.div`
  width: 24px;
  height: auto;
  margin: 0 auto;
  svg {
    width: 100%;
    height: auto;
    max-height: 24px;
    margin: 0 auto;
  }
  @media screen and (min-width: 780px) {
    margin: 0 auto;
  }
`;

export default ServicesMenu;
