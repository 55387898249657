import * as React from "react";
const SvgDiseaseDiagnosis = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 42"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.342 25.586c-.732-7.365-2.62-14.86 4.085-21.619M18.259 19.093l4.238 4.27M18.52 13.068l9.088 9.158M20.406 8.685l9.743 9.817M18.237 23.266c5.368.487 10.686.191 15.607-4.768"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M23.752 28.716c7.595 0 13.752-6.205 13.752-13.858C37.504 7.204 31.347 1 23.752 1 16.156 1 9.999 7.204 9.999 14.858c0 7.653 6.157 13.858 13.753 13.858ZM35.317 26.515 33.48 24.66M38.604 26.513a2.31 2.31 0 0 0-3.285 0 2.354 2.354 0 0 0 0 3.31l9.449 9.522a2.31 2.31 0 0 0 3.285 0 2.354 2.354 0 0 0 0-3.31l-9.449-9.522Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.007 37.734c4.409-3.925 3.73-8.263 3.152-12.605M3.362 27.714l6.77 6.825M5.257 25.259l6.315 6.36M8.816 24.477l2.845 2.863M1 27.69c3.499-3.526 7.026-3.62 10.89-3.227"
    />
  </svg>
);
export default SvgDiseaseDiagnosis;
