import * as React from "react";
const SvgDpydGenotyping = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 41 51"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13.41 1.616-.004.002c-2.154.892-3.17 3.348-2.27 5.485l.977 2.323c.9 2.137 3.375 3.146 5.529 2.254l.003-.002c2.154-.892 3.17-3.348 2.27-5.485l-.977-2.323c-.9-2.137-3.375-3.146-5.529-2.254ZM15.117 39.231l-2.613 7.317c-.78 2.184-3.196 3.328-5.397 2.555-2.2-.773-3.354-3.171-2.575-5.355l1.703-4.772M9.96 28.551l2.3-6.441c.78-2.184 3.197-3.328 5.397-2.555 2.2.773 3.354 3.171 2.575 5.355l-1.25 3.498"
    />
    <path
      stroke={props.color}
      strokeDasharray="4 4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.239 38.976 9.96 28.551M18.982 28.412 15.117 39.23"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m27.043 39.231 2.613 7.317c.78 2.184 3.196 3.328 5.397 2.555 2.2-.773 3.354-3.171 2.575-5.355l-1.703-4.772M32.2 28.551l-2.3-6.441c-.78-2.184-3.197-3.328-5.397-2.555-2.2.773-3.354 3.171-2.575 5.355l1.25 3.498"
    />
    <path
      stroke={props.color}
      strokeDasharray="4 4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M35.921 38.976 32.2 28.551M23.181 28.412l3.862 10.819"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m23.829 11.678.003.002c2.154.892 4.629-.117 5.528-2.254l.978-2.323c.9-2.137-.117-4.593-2.27-5.485l-.004-.002c-2.153-.892-4.628.116-5.528 2.253l-.978 2.323c-.9 2.137.117 4.594 2.27 5.486ZM20.781 20.086c2.335 0 4.227-1.877 4.227-4.193s-1.892-4.194-4.227-4.194c-2.334 0-4.226 1.878-4.226 4.194s1.892 4.193 4.226 4.193Z"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.298 28.221H7.264c-3.124 0-5.658 2.514-5.658 5.614v.004c0 3.1 2.534 5.613 5.658 5.613h27.033c3.124 0 5.657-2.513 5.657-5.613v-.004c0-3.1-2.533-5.614-5.657-5.614Z"
    />
  </svg>
);
export default SvgDpydGenotyping;
