import * as React from "react";
const SvgHereditaryMelanomaAndSkinCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 42"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#hereditary-melanoma-and-skin-cancer_Panel_svg__a)"
    >
      <path
        stroke={props.color}
        d="M1 7.158c4.802 0 4.802 5.113 9.6 5.113s4.803-5.113 9.6-5.113 4.803 5.113 9.6 5.113 4.802-5.113 9.6-5.113c4.797 0 4.803 5.113 9.6 5.113"
      />
      <path stroke={props.invert ? '#FFFFFF' : '#061C57'} d="M1 1.023h48" />
      <path
        stroke={props.color}
        d="M10 24.361a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM40 24.361a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM25 24.361a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM14.5 34.655a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM36.5 34.655a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM25.5 34.655a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M49 21.173v11.301c0 4.656-3.845 8.43-8.588 8.43H9.588C4.845 40.903 1 37.13 1 32.473V19.43"
      />
    </g>
    <defs>
      <clipPath id="hereditary-melanoma-and-skin-cancer_Panel_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h50v42H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHereditaryMelanomaAndSkinCancerPanel;
