import * as React from "react";
const SvgHereditaryParagangliomaPheochromocytomaPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 50"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.5 40.5c-.602-5.32.07-7.633 1-10.5M27.5 15.5C22 18 21 31.5 13 30.5"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 36.5c-5.5 2.5-9.5-2.5-7-9M29.5 22.5c-5.5 2.5-5 4-10.5 5.5M8.166 34c-.155 2.694 2.651 3.19 2.651 3.19"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.82 35.594s.934.788 1.869.309M26.237 30.094C26.128 27.25 23 26.748 23 26.748"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M27.863 28.127s-1.466-.564-2.303-.013"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m25.477 34.516-2.45 1.385c-.465.261-1.304 7.543 2.45 11.738M30.287 27.497h2.187c.501 0 1.626-1.256 1.388-1.674-.326-.575-.605-1.125-.553-1.288.076-.255.519-.327 1.56-.911 1.042-.581.925-1.386.503-2.005-.423-.619-3.242-4.08-3.315-5.136-.048-.682.592-2.62-.037-5.32-.348-1.488-.925-2.886-2.207-4.087M30.194 34.092c1.757-.641 1.578-2.074 1.578-2.913 0-1.13 1.654-1.95.777-3.631M20.548 33.448c2.385.782 8.079 1.282 9.646.644"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.158 23.935c-1.365-.553-3.05-1.58-3.05-2.831v-2.397c0-.817.564-1.6 1.75-1.6.928 0 2.087.026 2.623.799 0 0 3.644-.949 3.974-5.894.33-4.976 8.681-3.484 8.681-6.682-15.408-9.2-30.49.534-29.4 10.229.587 5.228 3.348 10.763 4.31 12.74.482 1.024.667 2.085.66 3.17"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.459 29.433s3.273-1.138 5.023-4.333M6.459 38.496s-2.957 4.823-2.957 8.332"
    />
  </svg>
);
export default SvgHereditaryParagangliomaPheochromocytomaPanel;
