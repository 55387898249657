import React from "react";
import { styled } from "styled-components";
import {
  ClinicianSupport,
  CompetitivePricing,
  FastTurnaround,
  HighQuality,
} from "../../../assets/svgs";

const WhyChoose = () => {
  return (
    <Grid>
      <div>
        <HighQuality />
        <p>
          Reliable High
          <br />
          Quality Results
          <br />
          <span>Based on a high<br />
          quality WES assay</span>
        </p>
      </div>
      <div>
        <ClinicianSupport />
        <p>
          Excellent Clinician
          <br />
          Support
        </p>
      </div>
      <div>
        <CompetitivePricing />
        <p>
          Competitive
          <br />
          Pricing
        </p>
      </div>
      <div>
        <FastTurnaround />
        <p>
          Fast Turnaround
          <br />
          Times*
        </p>
      </div>
    </Grid>
  );
};

const Grid = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2rem 3rem;
  font-weight: 700;
  justify-content: center;
  margin: 2rem 0;
  svg {
    width: 48px;
    height: auto;
  }
  div {
    align-items: flex-start;
    display: flex;
    gap: 2rem;
    p {
      margin: 0;
      span {
        font-weight: 300;
      }
    }
  }
  @media screen and (min-width: 780px) {
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default WhyChoose;
