import * as React from "react";
const SvgHereditaryLungCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#hereditary-lung-cancer-panel_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M17.395 14.81c-.662-1.804-2.848-2.397-4.278-1.147C8.297 17.87-1.108 28.59 1.426 46.566c.262 1.865 2.19 2.95 3.867 2.187a93.722 93.722 0 0 0 10.303-5.49c2.05-1.269 5.227-3.483 5.404-5.936V26.22M26 34v-8M30 14.803c.669-1.797 2.88-2.388 4.325-1.143C37.94 16.77 44.103 23.448 46 34"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="m16 19 3.353-2.47C20.856 15.426 22 14.308 22 12.54V2M31 19l-2.522-2.041C27.07 15.822 26 14.67 26 12.849V2M29 23c-1.466-1.327-3.229-2-5-2-1.763 0-3.527.664-5 2"
      />
      <path
        stroke={props.color}
        d="M33.915 49h-7.433c-.98 0-1.482-.553-1.482-1.637V35.985c0-1.08.502-1.637 1.482-1.637h20.037c.982 0 1.481.553 1.481 1.637v11.378c0 1.08-.502 1.637-1.481 1.637H36.88M29.182 42.72h3.136M29.182 45.86h3.136M29.182 37.488h3.136M35.455 41.674h3.136M35.455 45.86h3.136M41.727 43.767h3.137M41.727 40.627h3.137M35.455 37.488h3.136M41.727 37.488h3.137"
      />
    </g>
    <defs>
      <clipPath id="hereditary-lung-cancer-panel_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h49.305v50H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHereditaryLungCancerPanel;
