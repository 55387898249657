import * as React from "react";
const SvgHereditaryEndocrineCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 47"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.186 11.435c.11-.113.22-.22.334-.332 2.147-2.05 5.367-2.73 8.072-1.462 1.533.723 3.055 2.104 3.997 4.685a6.866 6.866 0 0 1 .22 3.956c-.494 2.088-1.633 5.423-4.135 7.538 0 0 1.758 6.52-3.335 8.667l1.123 2.361c2.6 5.477-5.432 10.386-9.276 4.444-.69-1.066-2.42-3.309-5.186-4.144"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28 36v2.492c0 .835-.863 1.508-1.934 1.508h-2.132C22.864 40 22 39.327 22 38.492V36M28 36c1.65-1.061 3.035-3.724 3.469-5.974.107-.572.168-1.17.347-1.716.179-.546.496-1.06.95-1.239 1.068-.42 2.202 1.176 3.234.636M13.814 11.435c-.11-.113-.22-.22-.334-.332-2.147-2.05-5.367-2.73-8.072-1.462-1.533.723-3.055 2.104-3.997 4.685a6.866 6.866 0 0 0-.22 3.956c.494 2.088 1.633 5.423 4.135 7.538 0 0-1.758 6.52 3.335 8.667l-1.123 2.361c-2.6 5.477 5.432 10.386 9.276 4.444.69-1.066 2.42-3.309 5.186-4.144"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22 36c-1.65-1.061-3.035-3.724-3.469-5.974-.107-.572-.168-1.17-.347-1.716-.179-.546-.496-1.06-.95-1.239-1.068-.42-2.202 1.176-3.234.636M11 14c1.183 1.312 2.026 3.03 2.39 4.873.25 1.262.34 2.71 1.197 3.55.858.84 2.204.692 3.162.011.958-.68 1.619-1.783 2.251-2.853M42 14c-1.577 1.312-2.701 3.03-3.187 4.873-.332 1.262-.453 2.71-1.596 3.55-1.144.84-2.938.692-4.215.011-1.278-.68-2.159-1.783-3.002-2.853"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M32.701 13.29h-.758c-.583 0-1.058-.52-1.058-1.162v-.245c0-.552-.453-.965-.949-.87-1.614.318-3.25.48-4.887.48h-.099c-1.635 0-3.272-.162-4.886-.48-.496-.095-.95.318-.95.87v.245c0 .641-.474 1.162-1.057 1.162h-.758c-.682 0-1.277.576-1.298 1.325-.022.772.545 1.407 1.243 1.407h.813c.583 0 1.058.522 1.058 1.163v.413c0 .425.278.79.66.869 1.713.36 3.442.533 5.176.533h.099c1.733 0 3.468-.18 5.175-.533.382-.078.66-.444.66-.869v-.413c0-.641.475-1.163 1.058-1.163h.813c.703 0 1.265-.635 1.243-1.407-.021-.75-.616-1.325-1.298-1.325ZM32.701 4.004h-.758c-.583 0-1.058-.456-1.058-1.017v-.215c0-.482-.453-.844-.949-.76-1.614.278-3.25.42-4.887.42h-.099c-1.635 0-3.272-.142-4.886-.42-.496-.084-.95.278-.95.76v.215c0 .561-.474 1.017-1.057 1.017h-.758c-.682 0-1.277.503-1.298 1.159-.022.676.545 1.232 1.243 1.232h.813c.583 0 1.058.456 1.058 1.017v.361c0 .373.278.692.66.76A28.62 28.62 0 0 0 24.95 9h.099c1.733 0 3.468-.157 5.175-.467a.784.784 0 0 0 .66-.76v-.361c0-.561.475-1.017 1.058-1.017h.813c.703 0 1.265-.556 1.243-1.232-.021-.656-.616-1.159-1.298-1.159Z"
    />
  </svg>
);
export default SvgHereditaryEndocrineCancerPanel;
