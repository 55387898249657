import * as React from "react";
const SvgHereditaryGastrointestinalCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 47"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#hereditary-gastrointestinal-cancer-panel_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M39.36 27.731c1.131-2.127 1.98-4.473 2.381-6.852.941-5.575-.56-11.33-6.557-14.838-1.145-.669-2.447-1.062-3.774-.997-2.427.122-3.45 1.613-3.867 3.047-.317 1.086-1.802 1.216-2.29.195C24.464 6.628 24 4.663 24 2.496V1.98M5.003 36.011c0-1.431-.034-3.504.134-5.097.125-1.184 1.73-1.633 2.523-.704.548.637 1.1 1.225 1.658 1.767 3.018 2.94 7.785 4.958 12.682 5.658"
      />
      <path
        stroke={props.color}
        d="M40.839 45.558H24.675c-1.107 0-1.675-.672-1.675-1.991V29.723c0-1.315.568-1.992 1.675-1.992h22.65c1.111 0 1.675.673 1.675 1.992v13.844c0 1.315-.568 1.991-1.675 1.991h-3.133M28 38.626h3M28 41.597h3M28 31.693h3M35 37.635h3M28 34.664h3M35 41.597h3M42 39.616h3M42 35.654h3M35 31.693h3M42 31.693h3"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M9 18.85v4.404c0 .75.465 1.434 1.2 1.774 1.44.672 2.818 1.155 4.017 1.438 5.016 1.18 9.257-1.561 9.736-5.876.358-3.22-1.458-6.096-1.458-6.096-3.146-5.38-3.249-10.575-3.249-10.575V1.98M1 35.654v-5.222c0-2.466 1.843-3.968 3.028-4.681.602-.362.972-.934.972-1.548v-4.395"
      />
    </g>
    <defs>
      <clipPath id="hereditary-gastrointestinal-cancer-panel_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h50v47H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHereditaryGastrointestinalCancerPanel;
