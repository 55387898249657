import React from "react";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";

const ClinicalServiceDetails = ({ service }) => {
  const {
    title,
    testName,
    testCode,
    serviceCategory,
    serviceSubCategory,
    _rawTurnaroundTime,
    _rawSampleRequirements,
    diseasesTargeted,
    whoTestFor,
    _rawTestApproach,
    numberOfGenes,
    panelContent,
    mitoGenomeIncluded,
    _rawAlternativePanelsAvailable,
    slug,
  } = service.node;
  console.log("helllllooooo", serviceCategory);
  return (
    <Container>
      <h5>Test Name</h5>
      <Text>{testName.replace("*", "")}</Text>
      <h5>Test Code</h5>
      <Text>{testCode}</Text>
      <h5>Test Category</h5>
      <Text>{serviceCategory.title}</Text>
      {serviceSubCategory && (
        <>
          <h5>Test Subcategory</h5>
          <Text>{serviceSubCategory.title}</Text>
        </>
      )}
      {diseasesTargeted && (
        <>
          <h5>Disease(s) Targeted</h5>
          <Text>{diseasesTargeted}</Text>
        </>
      )}
      {whoTestFor && (
        <>
          <h5>Who Is The Test For? </h5>
          <Text>{whoTestFor}</Text>
        </>
      )}
      {_rawTestApproach && (
        <>
          <h5>Test Approach</h5>
          <Text>
            <BlockContent blocks={_rawTestApproach} />
          </Text>
        </>
      )}
      {numberOfGenes && (
        <>
          <h5>
            {serviceCategory.title === "Single Gene Tests"
              ? "No. of Variants"
              : "No. of Genes"}
          </h5>
          <Text>{numberOfGenes}</Text>
        </>
      )}
      {panelContent && (
        <>
          <h5>
            {serviceCategory.title === "Single Gene Tests"
              ? "Variants"
              : "Panel Content"}
          </h5>
          <Text>
            <small>{panelContent}</small>
          </Text>
        </>
      )}
      {mitoGenomeIncluded !== null && (
        <>
          <h5>Mitochondrial Genome Included In The Analysis</h5>
          <Text>{mitoGenomeIncluded ? "Yes" : "No"}</Text>
        </>
      )}
      <h5>Deliverables</h5>
      <Text>
        <p>
          Clinical Report{" "}
          <Link to="/services/clinical/resources/downloadable-forms">
            (Download a sample report here)
          </Link>
        </p>
      </Text>
      <h5>Turnaround Time</h5>
      <Text>
        <BlockContent blocks={_rawTurnaroundTime} />
      </Text>
      <h5>Specimen Requirements</h5>
      <Text>
        <p>
          For detailed information about the sample requirements, please consult
          our{" "}
          <Link to="/services/clinical/resources/sample-requirements">
            clinical sample requirements page.
          </Link>
        </p>
        {_rawSampleRequirements && (
          <BlockContent blocks={_rawSampleRequirements} />
        )}
      </Text>
      {_rawAlternativePanelsAvailable && (
        <>
          <h5>Alternative Panels Available</h5>
          <Text id="link">
            <BlockContent blocks={_rawAlternativePanelsAvailable} />
          </Text>
        </>
      )}
      <SmallText>
        *Turnaround times are estimated from receipt of satisfactory specimen
        and test request form at the laboratory to release of clinical report.
        The turnaround time may vary depending on the nature of the specimen and
        the complexity of the investigation required. The above table is only a
        guideline and the complexity of a case and the requirement for further
        investigations may change this.
        <br />
      </SmallText>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
  margin-bottom: 4rem;
  h5 {
    font-size: 1rem;
    padding: 1rem 1.25rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  span {
    text-decoration: none !important;
    position: relative;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  p {
    margin-bottom: 1.25rem;
  }
  small {
    font-style: italic;
  }
  a {
    color: var(--genseq-purple);
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const SmallText = styled.span`
  position: absolute;
  left: 0;
  bottom: -7rem;
  font-size: 0.75rem;
  @media screen and (min-width: 1024px) {
    left: 1.25rem;
    bottom: -3.5rem;
  }
`;

export default ClinicalServiceDetails;
