import React, { useEffect, useContext, useRef, useState } from "react";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import { Script } from "gatsby";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import {
  NavigationBar,
  Footer,
  Dialog,
  RequestQuoteForm,
  RequestAccountForm,
} from "../../components";
import { LocalContext } from "../../context";
import { GlobalStyle } from "../../styles";
import { useWindowSize } from "../../hooks";

const Layout = ({ children }) => {
  const { scrollYProgress } = useScroll();
  const { pathname, search } = useLocation();
  const { windowWidth } = useWindowSize();
  const [showWebsiteOverlay, setShowWebsiteOverlay] = useState(false);

  const queryParams = queryString.parse(search);

  const {
    showRequestFormDialog,
    setShowRequestFormDialog,
    setShowContactFormDialog,
    showRequestAccountFormDialog,
    setShowRequestAccountFormDialog,
    setShowServicesMenu,
    setDropdownOpen,
    setExpandedServices,
    setUserHasScrolled,
    userHasScrolled
  } = useContext(LocalContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setUserHasScrolled(true);
      } else {
        setUserHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    if (queryParams.contact) {
      setShowContactFormDialog(true);
    }
    if (queryParams.requestAccount) {
      setShowRequestAccountFormDialog(true);
    }
    if (queryParams.services) {
      if (windowWidth >= 1024) {
        setShowServicesMenu(true);
      } else {
        setDropdownOpen(true);
        setExpandedServices(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!showWebsiteOverlay && userHasScrolled) {
      (function waitForCookieYes() {
        if (window.getCkyConsent) {
          const { isUserActionCompleted } = window.getCkyConsent();
          if (!isUserActionCompleted) {
            setShowWebsiteOverlay(true);
            document.addEventListener('cookieyes_consent_update', () =>
              setShowWebsiteOverlay(false)
            );
          }
        } else {
          setTimeout(waitForCookieYes, 50);
        }
      })();
    }
  }, [userHasScrolled]);

  const requestFormDialogRef = useRef(null);
  const requestAccountFormDialogRef = useRef(null);

  const closeRequestFormDialog = () => {
    requestFormDialogRef.current.setAttribute("data-state", "close");
    requestFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowRequestFormDialog(false);
  };

  const closeRequestAccountFormDialog = () => {
    requestAccountFormDialogRef.current.setAttribute("data-state", "close");
    requestAccountFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowRequestAccountFormDialog(false);
  };

  return (
    <AnimatePresence mode="wait">
      <GlobalStyle />
      <Container>
        <NavigationBar scrollYProgress={scrollYProgress} pathname={pathname} />
        {children}
        <Footer pathname={pathname} />
        {process.env.NODE_ENV !== 'development' && (
        <AnimatePresence>{showWebsiteOverlay && <WebsiteOverlay />}</AnimatePresence>
      )}
      </Container>
      <Dialog
        large
        ref={requestFormDialogRef}
        onDismiss={closeRequestFormDialog}
        isVisible={showRequestFormDialog}
      >
        <RequestQuoteForm closeRequestFormDialog={closeRequestFormDialog} />
      </Dialog>
      <Dialog
        large
        ref={requestAccountFormDialogRef}
        onDismiss={closeRequestAccountFormDialog}
        isVisible={showRequestAccountFormDialog}
      >
        <RequestAccountForm
          closeRequestAccountFormDialog={closeRequestAccountFormDialog}
        />
      </Dialog>
    </AnimatePresence>
  );
};

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  #greenLink {
    color: var(--genseq-green);
    &:before {
      background: var(--genseq-green);
    }
  }
  #darkLink {
    color: var(--genseq-blue);
    &:before {
      background: var(--genseq-blue);
    }
  }
`;

const WebsiteOverlay = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  },
  transition: {
    duration: 0.5
  }
})`
  backdrop-filter: blur(0.125rem);
  background-color: rgb(0 0 0 / 0.25);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 7;
`;

export default Layout;
