import * as React from "react";
const SvgHereditaryPancreaticCancerPane = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 40"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#hereditary-pancreatic-cancer-pane_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M2.678 14C-.484 23.127 7.68 38.326 21.577 25.499c1.256-1.16 3.237-.33 3.359 1.416.243 3.532-.206 7.85-1.335 12.085M31 37v2M24 19.874c2.82-1.467 5.636-1.026 6.57.863.172.344.314.773.43 1.263M13.195 16.29c2.644-5.345 9.191-7.17 17.48-8.522M23.173 18.305c.773.572.922 1.636.328 2.38a1.807 1.807 0 0 1-2.471.317"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M21.05 20.99c.502.803.23 1.848-.607 2.33-.837.484-1.919.223-2.42-.584M18.06 22.825c-.024.937-.834 1.678-1.807 1.655-.973-.023-1.743-.803-1.719-1.74"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M14.534 22.808c-.278.898-1.258 1.414-2.193 1.146-.936-.268-1.468-1.211-1.19-2.112M11.134 21.842a1.814 1.814 0 0 1-2.496-.033 1.654 1.654 0 0 1 .034-2.403"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M8.686 19.429c-.899.359-1.933-.053-2.306-.918-.372-.865.055-1.861.953-2.22M7.238 16.31c-.96-.16-1.603-1.038-1.437-1.962.166-.924 1.078-1.545 2.037-1.385M7.838 12.963c-.922-.303-1.413-1.27-1.095-2.158.319-.888 1.32-1.362 2.241-1.055M8.984 9.734a1.655 1.655 0 0 1 .14-2.397 1.811 1.811 0 0 1 2.488.134"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M11.591 7.48c-.335-.88.133-1.857 1.048-2.177.915-.32 1.929.127 2.261 1.009M14.904 6.302c-.241-.908.329-1.835 1.271-2.067.942-.232 1.905.317 2.146 1.225"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M18.321 5.463c-.102-.934.6-1.77 1.566-1.868.967-.098 1.838.578 1.94 1.509"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M21.827 5.104c-.167-.924.478-1.803 1.437-1.963.96-.16 1.872.46 2.038 1.385M25.302 4.522c-.027-.937.742-1.717 1.715-1.743.973-.026 1.783.715 1.81 1.652"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M28.824 4.428c-.119-.93.57-1.78 1.536-1.894.966-.114 1.848.548 1.966 1.48"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M32.326 4.016c-.196-.917.42-1.815 1.373-2.005.953-.189 1.885.405 2.082 1.323"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M35.774 3.337c-.237-.91.34-1.832 1.282-2.06.942-.229 1.902.326 2.139 1.234M39.205 2.524c-.098-.934.607-1.766 1.577-1.861.97-.095 1.834.584 1.932 1.518M46.131 2.991c.665.686.62 1.763-.091 2.4a1.814 1.814 0 0 1-2.492-.088M43.541 5.306c.492.84.187 1.907-.678 2.384-.864.477-1.963.18-2.454-.66"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M40.409 7.027c.359.901-.099 1.913-1.024 2.263-.926.35-1.97-.095-2.333-.996"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M37.056 8.3c.447.862.088 1.914-.8 2.348-.889.434-1.97.085-2.418-.777"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M33.842 9.871c.515.826.247 1.9-.604 2.403a1.831 1.831 0 0 1-2.475-.587"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M30.763 11.68a1.72 1.72 0 0 1-.495 2.426c-.823.536-1.946.32-2.498-.483M27.76 13.616c.427.872.047 1.917-.851 2.332-.899.415-1.973.045-2.4-.826 0 0 .942 3.115-1.333 3.18M42.714 2.158c.24-.907 1.197-1.46 2.143-1.23.942.231 1.515 1.152 1.278 2.063"
      />
      <path
        stroke={props.color}
        d="M36.915 37h-7.433c-.98 0-1.482-.553-1.482-1.637V23.985c0-1.08.502-1.637 1.482-1.637h20.037c.982 0 1.481.553 1.481 1.637v11.378c0 1.08-.502 1.637-1.481 1.637H39.88M32.182 30.72h3.136M32.182 33.86h3.136M32.182 25.488h3.136M38.455 29.674h3.136M38.455 33.86h3.136M44.727 31.767h3.137M44.727 28.627h3.137M38.455 25.488h3.136M44.727 25.488h3.137"
      />
    </g>
    <defs>
      <clipPath id="hereditary-pancreatic-cancer-pane_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h52v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHereditaryPancreaticCancerPane;
