import * as React from "react";
const SvgPharmacogenomic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 43"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M39.273 14.346C36.051 7.1 28.145 2.505 19.671 3.601 9.46 4.924 2.293 14.008 3.658 23.895c1.366 9.887 10.75 16.826 20.959 15.504"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.902 7.74c-5.887 9.283 4.847 18.239-1.038 27.52"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.037 17.2c-6.62 8.31-18.691.292-25.31 8.6M32.292 18.06l-7.855-5.16M27.927 20.64l-3.49-2.58M10.473 24.94l7.855 6.02M13.964 22.36l4.364 2.58"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M32.292 36.98c3.373 0 6.109-2.695 6.109-6.02 0-3.324-2.736-6.02-6.11-6.02-3.373 0-6.109 2.696-6.109 6.02 0 3.325 2.736 6.02 6.11 6.02ZM32.292 27.52v6.88"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.655 35.728c0 2.117 1.758 3.833 3.927 3.833 2.17 0 3.928-1.716 3.928-3.834V21.035c0-2.118-1.758-3.833-3.928-3.833s-3.927 1.715-3.927 3.833v5.58M38.4 28.38h6.11"
    />
  </svg>
);
export default SvgPharmacogenomic;
