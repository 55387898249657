import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { LinkedinIcon } from "../../../assets/svgs";

const SecondaryFooter = () => {
  return (
    <Container>
      <InnerContainer>
        <Social>
          <a
            href="https://www.linkedin.com/company/genseq"
            target="_blank"
            aria-label="Company LinkedIn Profile"
          >
            <LinkedinIcon />
          </a>
        </Social>
        <Utility>
          <p>©2023 Genseq All Rights Reserved.</p>
          <div>
            <div
              type="button"
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              Terms & Conditions
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/cookie-policy");
              }}
            >
              Cookie Policy
            </div>
            <div type="button" tabIndex={0} className="cky-banner-element">
              Cookie Preferences
            </div>
          </div>
        </Utility>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.footer`
  background: transparent;
  bottom: 0;
  color: var(--genseq-blue);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  position: relative;
  width: 100%;
  margin-top: auto;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  grid-column: 1 / 7;
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-auto-flow: dense;
    grid-column: 2 / 12;
    grid-template-columns: repeat(4, 1fr);
    padding: 0.625rem 0 2rem 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.125rem;
  a {
    height: 24px;
    width: 24px;
    svg {
      height: 100%;
      width: 100%;
      circle {
        fill: var(--genseq-blue);
      }
      path {
        fill: #ffffff;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    gap: 1.25rem;
    grid-column: 4/5;
  }
`;

const Utility = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 0.25rem;
  div {
    display: flex;
    gap: 1.125rem;
    div {
      color: var(--genseq-blue);
      cursor: pointer;
      height: max-content;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-thickness: 1px;
      &:hover {
        font-weight: 400;
        margin-top: -1px;
      }
    }
  }
  a {
    color: var(--genseq-blue);
    height: max-content;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
    &:hover {
      font-weight: 400;
      margin-top: -1px;
    }
  }
  @media screen and (min-width: 1024px) {
    gap: 1.75rem;
    flex-direction: row;
    font-size: 1rem;
    grid-column: 1 / 4;
  }
`;

export default SecondaryFooter;
