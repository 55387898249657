import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { useWindowSize } from "../../../hooks";
import { PlayButton, Close } from "../../../assets/svgs";
import { Video, VideoCarousel } from "../../../components";

const PatientVideos = () => {
  const [videoPlaying, setVideoPlaying] = useState(null);
  const { windowWidth } = useWindowSize();
  const videos = [
    {
      id: 1,
      title: "Overview of genetics and prenatal genetic testing",
      link: "https://player.vimeo.com/video/967721254?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;dnt=1",
    },
    {
      id: 2,
      title: "Chromosomal testing options",
      link: "https://player.vimeo.com/video/967721587?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;dnt=1",
    },
    {
      id: 3,
      title: "What is NIPT?",
      link: "https://player.vimeo.com/video/967721999?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;dnt=1",
    },
    {
      id: 4,
      title: "Understanding NIPT results",
      link: "https://player.vimeo.com/video/967722375?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;dnt=1",
    },

    {
      id: 5,
      title: "Understanding options for expanded prenatal genetic screening",
      link: "https://player.vimeo.com/video/967722728?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;dnt=1",
    },
  ];

  const { videoThumbnailMobile, videoThumbnail } = useStaticQuery(
    graphql`
      query {
        videoThumbnailMobile: allFile(
          filter: {
            relativePath: { eq: "assets/images/patient-video-thumbnail.png" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 316)
              }
            }
          }
        }
        videoThumbnail: allFile(
          filter: {
            relativePath: { eq: "assets/images/patient-video-thumbnail.png" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 216)
              }
            }
          }
        }
      }
    `
  );

  return (
    <Container>
      {videoPlaying && (
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            setVideoPlaying(null);
          }}
        >
          Close
          <Close />
        </CloseButton>
      )}
      {videoPlaying ? (
        <VideoExpanded
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
            opacity: { duration: 0.2 },
          }}
        >
          <motion.div
            key={videoPlaying}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Video link={videos[videoPlaying - 1].link} />
            <p>{videos[videoPlaying - 1].title}</p>
          </motion.div>
          <VideoCarousel
            videos={videos}
            videoPlaying={videoPlaying}
            setVideoPlaying={setVideoPlaying}
            videoThumbnail={videoThumbnail.edges[0].node}
          />
        </VideoExpanded>
      ) : (
        <Overview
          initial="open"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
            opacity: { duration: 0.2 },
          }}
        >
          {videos.map((video) => (
            <VideoPreview key={video.id}>
              <VideoThumbnail>
                <GatsbyImage
                  image={getImage(
                    windowWidth < 780
                      ? videoThumbnailMobile.edges[0].node
                      : videoThumbnail.edges[0].node
                  )}
                />
                <Overlay
                  onClick={() => setVideoPlaying(video.id)}
                  whileHover={{
                    scale: 1.075,
                    transition: { duration: 0.3 },
                  }}
                  whileTap={{ scale: 0.9 }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                  >
                    <PlayButton />
                  </motion.div>
                </Overlay>
              </VideoThumbnail>
              <p>{video.title}</p>
            </VideoPreview>
          ))}
        </Overview>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 2rem;
  @media screen and (min-width: 780px) {
    margin: 0;
  }
`;

const Overview = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(1, 316px);
  margin: 0 auto;
  gap: 1.25rem;
  width: 100%;
  max-width: 316px;
  @media screen and (min-width: 780px) {
    display: grid !important;
    grid-template-columns: repeat(3, 216px);
    max-width: 100%;
    width: auto;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 216px);
  }
  @media screen and (min-width: 1380px) {
    grid-template-columns: repeat(5, 216px);
  }
`;

const VideoPreview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
`;

const VideoThumbnail = styled.div`
  border-radius: 10px;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 316px;
  @media screen and (min-width: 780px) {
    height: 121px;
    width: 216px;
  }
`;

const Overlay = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    svg {
      height: 42px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
    }
  }
`;

const VideoExpanded = styled(motion.div)`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 700;
  gap: 1.625rem;
  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const CloseButton = styled.button`
  align-items: center;
  color: var(--genseq-blue);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: -2rem;
  right: 1rem;
  z-index: 10;
  svg {
    height: 12px;
    width: 12px;
  }
`;

export default PatientVideos;
