import * as React from "react";
const SvgNeurofibromatosisPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 44 44"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M29.818 15.598a3.166 3.166 0 0 0-3.658-.588 4.011 4.011 0 0 0-.634-.834 4.02 4.02 0 0 0-5.683 0c-.054.054-.1.112-.15.167a5.218 5.218 0 0 0-5.937 1.009 5.197 5.197 0 0 0-1.007 5.937c-.075.067-.15.13-.22.2a5.194 5.194 0 0 0 0 7.36 5.215 5.215 0 0 0 7.369 0c.045-.046.087-.1.133-.146a7.045 7.045 0 0 0 4.944.93c.058.062.112.129.17.191a4.016 4.016 0 0 0 5.68 0 4.006 4.006 0 0 0 0-5.67 2.432 2.432 0 0 0-.193-.171 7.043 7.043 0 0 0-.547-4.228 3.155 3.155 0 0 0-.275-4.153l.008-.004Z"
    />
    <circle cx={22} cy={22} r={18} stroke={props.color} strokeWidth={2} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeWidth={2}
      d="M22 2v6M22 20v4M24 22h-4M22 36v6M42 22h-6M8 22H2"
    />
    <circle cx={17.5} cy={18.5} r={0.5} stroke={props.invert ? '#FFFFFF' : '#061C57'} />
    <circle cx={27.5} cy={26.5} r={0.5} stroke={props.invert ? '#FFFFFF' : '#061C57'} />
    <circle cx={16.5} cy={25.5} r={0.5} stroke={props.invert ? '#FFFFFF' : '#061C57'} />
  </svg>
);
export default SvgNeurofibromatosisPanel;
