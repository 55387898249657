import { createGlobalStyle } from "styled-components";
import "../assets/fonts/fonts.css";

export const GlobalStyle = createGlobalStyle`
  :root {
    --genseq-blue: #061C57;
    --genseq-green: #1AE299;
    --genseq-purple: #645FE6;
    --genseq-orange: #FF7D50;
    --prenatal-blue: #36CBFF;

    font-size: 16px;
    line-height: 1.25;
  } 

  * {
      margin:0;
      padding:0;
      box-sizing:border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    background: #F8F8F8;
    color: var(--genseq-blue);
    overflow-x: hidden;
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    max-width: 100vw;
  }

  a {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }

  button {
    background: transparent;
    border: none;
    color: white;
    font-family: 'Outfit', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    outline: none;
    &:focus{
        outline:none;
        border:none;
    }
  }

  .scroll-lock {
    overflow: hidden;
  }

  sup {
    line-height: 0;
  }

  ul, ol {
    margin-left: 1.75rem;
    ul {
      list-style-type: disc;
    }
  }
`;
