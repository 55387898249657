import * as React from "react";
const SvgComprehensiveHereditaryCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 44 48"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#comprehensive-hereditary-cancer-panel_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M27 1.043c2.683 3.33 7.778 2.427 11.119 4.752 3.887 2.706 4.15 7.076 4.325 11.034.655 14.916.55 10.881.55 19.693M37 28.174l1 8.348M37 45.913l-.602-1.485c-2.448-6.042-3.045-12.89-1.684-19.384M12 25.044c.62 2.062.95 4.165 1 6.26M19 1.043c-2.515 3.32-7.292 2.42-10.424 4.737-3.644 2.699-3.89 7.055-4.055 11-.614 14.871-.515 6.78-.515 15.568M10 28.174v4.174"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M27 23.738c1.267.825 2.734 1.347 4.09 1.25 3.282-.23 5.432-3.204 5.91-6.988M10 18c.5 3.78 2.773 6.755 6.235 6.989 1.593.107 3.333-.553 4.765-1.547"
      />
      <path
        stroke={props.color}
        d="M9.527 46.957h-7.11C1.48 46.957 1 46.405 1 45.325V33.98c0-1.077.48-1.632 1.417-1.632h19.166c.94 0 1.417.551 1.417 1.632v11.345c0 1.077-.48 1.631-1.417 1.631h-9.218M5 40.696h3M5 43.826h3M5 35.478h3M11 39.652h3M11 43.826h3M17 41.74h3M17 38.609h3M11 35.478h3M17 35.478h3"
      />
    </g>
    <defs>
      <clipPath id="comprehensive-hereditary-cancer-panel_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h44v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComprehensiveHereditaryCancerPanel;
