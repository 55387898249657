import * as React from "react";
const SvgTtrSequencing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 46 46"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#ttr-sequencing_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M6.611 41.55s.97-17.997 2.913-18.183c1.944-.185 1.555 16.165 2.525 18.183M25.264 41.55s.97-17.997 2.914-18.183c1.943-.185 1.554 16.165 2.524 18.183M30.702 41.55s.97-17.997 2.914-18.183c1.943-.185 1.554 16.165 2.524 18.183M12.053 41.55s.97-26.973 2.913-27.162c1.944-.189 1.555 25.144 2.525 27.162M18.446 41.55s.97-31.237 2.914-31.423c1.943-.185 1.554 29.408 2.524 31.423M36.144 41.55s.97-38.868 2.913-39.054c1.944-.185 1.555 37.039 2.525 39.054"
      />
      <path stroke={props.color} d="M3.833 1.917v43.125M45.042 42.167H.958" />
    </g>
    <defs>
      <clipPath id="ttr-sequencing_svg__a">
        <path fill="#fff" d="M0 0h46v46H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTtrSequencing;
