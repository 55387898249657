import * as React from "react";
const SvgHereditaryCancerHighRiskPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 43"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m34.777 19.978 2.137-2.59C41.715 11.658 37.589 3 30.057 3S18.398 11.658 23.2 17.388l19.267 22.996L48 35.564 27.945 11.935c-2.076-2.474-2.48-5.493-1.667-8.128M30.06 25.572 26.5 30M22 24l3.494-3.875"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.322 9.028c1.411-.919 3.329-.767 4.567.46a3.551 3.551 0 0 1 0 5.074"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.152 41.142H2.61c-1.064 0-1.61-.647-1.61-1.915V25.915C1 24.65 1.546 24 2.61 24h21.78c1.068 0 1.61.647 1.61 1.915v13.312c0 1.264-.546 1.915-1.61 1.915h-3.013M5.808 34.475h2.884M5.808 37.332h2.884M5.808 27.81h2.884M12.539 33.523h2.884M5.808 30.666h2.884M12.539 37.332h2.884M19.27 35.428h2.884M19.27 31.619h2.884M12.539 27.81h2.884M19.27 27.81h2.884"
    />
  </svg>
);
export default SvgHereditaryCancerHighRiskPanel;
