import * as React from "react";
const SvgAbbvieLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 112 28"
    {...props}
  >
    <path
      fill="#fff"
      d="M102.069 21c0-.91-.597-1.26-1.493-1.26h-6.87c-3.36 0-4.18-1.89-4.33-3.08h9.333c2.838 0 3.734-1.96 3.734-3.22 0-1.33-.896-3.22-3.734-3.22h-5.152c-4.853 0-6.421 3.08-6.421 5.6 0 2.73 1.792 5.6 6.421 5.6h8.512V21Zm-8.362-9.1h4.48c1.642 0 2.016.91 2.016 1.54 0 .56-.374 1.54-2.016 1.54h-8.96c.149-.98 1.12-3.08 4.48-3.08ZM73.77 20.79c-.523.63-.747.91-1.27.91-.672 0-.821-.28-1.269-.91-1.045-1.4-7.317-10.5-7.317-10.5h.896c1.493 0 1.941.63 2.389 1.33.373.56 5.45 7.84 5.45 7.84s4.78-6.93 5.451-7.91c.448-.63 1.046-1.26 2.464-1.26h.672s-6.57 9.38-7.466 10.5Zm-47.115.7c-1.12 0-1.568-.42-1.717-1.26l-.299-1.19c-.299.49-1.792 2.45-5.077 2.45h-3.36c-5.078 0-6.496-3.22-6.496-5.6 0-2.66 1.717-5.6 6.496-5.6h3.584c3.658 0 5.674 1.96 6.122 4.41.374 2.03 1.344 6.79 1.344 6.79h-.597Zm-7.392-9.45h-2.837c-3.435 0-4.555 2.03-4.555 3.85 0 1.82 1.12 3.85 4.555 3.85h2.837c3.584 0 4.555-2.1 4.555-3.85 0-1.54-.896-3.85-4.555-3.85Zm64.288-3.57c.523 0 1.045-.28 1.045-.91v-.35c0-.56-.522-.91-1.045-.91-.523 0-1.045.28-1.045.91v.35c0 .63.522.91 1.045.91Zm-.97 1.82h.447c.971 0 1.568.49 1.568 1.47v9.73h-.448c-.97 0-1.568-.56-1.568-1.54v-9.66ZM30.612 11.9c.448-.42 1.792-1.61 4.406-1.61h3.36c5.077 0 6.57 3.22 6.57 5.6 0 2.66-1.792 5.6-6.57 5.6h-3.584c-3.659 0-6.272-2.17-6.272-5.6V6.51h.597c1.045 0 1.493.49 1.493 1.33v4.06Zm4.704 7.84h2.838c3.36 0 4.554-2.03 4.554-3.85 0-1.82-1.12-3.85-4.554-3.85h-2.838c-3.584 0-4.554 2.1-4.554 3.85-.075 1.54.896 3.85 4.554 3.85Zm14.038-7.84c.448-.42 1.792-1.61 4.405-1.61h3.36c5.077 0 6.496 3.22 6.496 5.6 0 2.66-1.717 5.6-6.496 5.6h-3.584c-3.659 0-6.272-2.17-6.272-5.6V6.51h.597c1.046 0 1.494.49 1.494 1.33v4.06Zm4.704 7.84h2.837c3.435 0 4.555-2.03 4.555-3.85 0-1.82-1.12-3.85-4.555-3.85h-2.837c-3.584 0-4.555 2.1-4.555 3.85-.075 1.54.896 3.85 4.555 3.85Z"
    />
  </svg>
);
export default SvgAbbvieLogo;
