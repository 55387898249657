import * as React from "react";
const SvgHereditaryProstateCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 44"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 5c3.161 4.624 6.476 11.103 7.6 19m.4 19v-4M38 5c-3.398 5.68-7 14.163-7 24.633V43M37.368 30.36l-.945-.035a2.046 2.046 0 0 1-1.913-1.648c-.243-1.265-.245-2.91 1.18-3.472a.918.918 0 0 0 .515-.515c.562-1.425 2.207-1.423 3.472-1.18.92.177 1.616.985 1.648 1.913l.035.945M7.838 14s-1.886 5.207 0 10.52"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M48 5.34c-.027 5.049-6.027 9.37-14.5 11.141M2 5.34c-.027 5.072 5.983 9.41 14.5 11.166M42.162 14S47.551 28.88 31 34"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.279 38.632H3.055c-.913 0-1.381-.524-1.381-1.552V26.291c0-1.024.468-1.552 1.381-1.552h18.68c.916 0 1.381.524 1.381 1.552v10.79c0 1.023-.468 1.551-1.38 1.551H9.044M5.194 33.003h3.009M5.194 35.433h3.009M5.194 28.143h3.009M10.88 32.962h3.012M5.194 30.573h3.009M10.88 35.433h3.012M16.577 34.313h3.012M16.577 31.226h3.012M10.88 28.143h3.012M16.577 28.143h3.012"
    />
  </svg>
);
export default SvgHereditaryProstateCancerPanel;
