import * as React from "react";
const SvgHereditaryRenalCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 34"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#hereditary-renal-cancer-panel_svg__a)"
    >
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M41.605 17.467c.496-2.442.524-4.978.089-7.452-.666-3.791-2.258-6.013-3.638-7.272-1.42-1.294-3.303-1.928-5.162-1.696-1.12.14-1.972.562-2.622 1.092 0 0-1.208.977-1.27 2.441-.037.892.35 2.249 2.294 2.249M31 15.442c-2.194-.29-3.5 1.333-4.219 2.777-.706 1.42-.97 3.09-.64 4.686.408 1.966 1.542 3.137 2.608 3.824M19 31.875l-.169-13.36c-.019-.831-.204-1.657-.605-2.368-.535-.95-1.277-1.496-2.226-1.768M10.946 15.183c2.218-.279 3.539 1.284 4.264 2.675.714 1.368.981 2.976.647 4.513-.412 1.893-1.558 3.022-2.635 3.683-1.425.875-3.094 1.132-4.697.817-3.457-.68-5.35-3.839-6.368-6.689C.993 16.94.714 13.377 1.289 9.943c.63-3.76 2.136-5.965 3.443-7.214C6.076 1.446 7.858.817 9.617 1.047c1.06.14 1.866.557 2.481 1.083 0 0 1.144.97 1.202 2.422.035.885-.33 2.23-2.171 2.23M23 31.875l.169-13.36c.019-.831.204-1.657.605-2.368.535-.95 1.277-1.496 2.226-1.768"
      />
      <path
        stroke={props.invert ? '#FFFFFF' : '#061C57'}
        d="M31 10.263c-3.713 0-6.004 1.094-7.4 2.304a3.206 3.206 0 0 1-4.2 0c-1.4-1.21-3.69-2.305-7.4-2.305"
      />
      <path
        stroke={props.color}
        d="M38.754 32.23h-8.136c-.897 0-1.358-.546-1.358-1.616V19.387c0-1.066.46-1.615 1.358-1.615H48.98c.9 0 1.358.546 1.358 1.615v11.227c0 1.066-.46 1.615-1.358 1.615h-7.507M32.717 28.9h2.958M32.717 21.314h2.958M38.307 26.329h2.96M32.717 23.843h2.958M38.307 28.9h2.96M43.91 27.735h2.957M43.91 25.09h2.957M38.307 23.843h2.96M43.91 22.444h2.957"
      />
    </g>
    <defs>
      <clipPath id="hereditary-renal-cancer-panel_svg__a">
        <path fill={props.invert ? '#FFFFFF' : '#061C57'} d="M0 0h52v34H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHereditaryRenalCancerPanel;
